
import { ITopic } from "@/core/data/topic";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { boolean } from "yup/lib/locale";
export default defineComponent({
  props: {
    topic: Object as PropType<ITopic>,
    i: Number,
    isTeacher: Boolean,
  },
  setup(props, { emit }) {
    return {};
  },
});
