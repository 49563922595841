
import CreateTopicModal from "@/views/studio-lab/components/modals/CreateTopicModal.vue";
import { defineComponent, ref, onMounted } from "vue";
import TopicService from "@/core/services/TopicService";
import { ITopic } from "@/core/data/topic";
import TopicCard from "@/views/studio-lab/components/cards/TopicCard.vue";
import { SearchTopicFilter } from "@/core/filterData/searchTopicFilter";
import TopicStatus from "@/core/constants/TopicStatus";
import UserRoleService from "@/core/services/UserRoleService";
export default defineComponent({
  name: "view-all-topics",
  components: {
    CreateTopicModal,
    TopicCard,
  },
  setup() {
    const topicModalRef = ref();
    const todayTopics = ref<Array<ITopic>>([]);
    const recentTopics = ref<Array<ITopic>>([]);
    const upComingTopics = ref<Array<ITopic>>([]);
    const todayTopicPage = ref(1);
    const isTeacher = ref<boolean>(false);

    const initTopics = () => {
      var searchTopicFilter: SearchTopicFilter = {
        page: todayTopicPage.value,
        size: 6,
        status: TopicStatus.ON_GOING,
      };
      TopicService.getTopics(searchTopicFilter).then((response) => {
        todayTopics.value = response.data.data;
      });

      searchTopicFilter.status = TopicStatus.RECENT;
      TopicService.getTopics(searchTopicFilter).then((response) => {
        recentTopics.value = response.data.data;
      });

      searchTopicFilter.status = TopicStatus.UP_COMING;
      TopicService.getTopics(searchTopicFilter).then((response) => {
        upComingTopics.value = response.data.data;
      });
    };

    const getTeacher = () => {
      UserRoleService.isTeacher().then((response) => {
        isTeacher.value = response.data;
      });
    };

    onMounted(() => {
      initTopics();
      getTeacher();
    });

    const createTopic = () => {
      topicModalRef.value.showModal();
    };

    return {
      topicModalRef,
      createTopic,
      initTopics,
      todayTopics,
      recentTopics,
      upComingTopics,
      isTeacher,
    };
  },
});
